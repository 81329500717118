export default (state = {
    status: false,
   
}, action) => {
    switch (action.type) { 
        case "USER_DRAWER":
                    return { ...state, status: action.response };
        default:
            return state;
    }
};


