import React, { useState, useEffect, memo } from "react";
import {
  IconButton,
  Divider,
  Popover,
  Skeleton,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./usersToolbar.css";
import LanguageTranslation from "../Language/LanguageTranslation";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  marginSpace: {
    marginRight: "4px",
  },
  camroot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  appbar: {
    backgroundColor: theme.palette.background.dark,
    maxHeight: "50",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    //   '&:hover': {
    //     backgroundColor: theme.palette.primary.main,
    //   },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const UserToolBarMoreOption = ({
  listtypeopenClose,
  //menuopen,
  anchorEl,
  disableDelete,
  showDelete,
  toolbarViewOpen,
  toolbarDuplicateOpen,
  toolbarViewShow,
  disableView,
  deleteData,
  columnChooserclick,
  columnChooserShow,
  excelExportShow,
  excelExport,
  importOpen,
  showImport,
  setMenuOpen,
  disableDocumentView,
  showDocumentView,
  toolbarDocumentViewOpen,
  showInlineEdit,
  setEnableInlineEdit,
  enableInlineEdit,
  inlineEditCancel,
  setBtnClicked,
  showDependancy,
  editDependancy,
  editDisable,
  callingFromGantt,
  callingFromDocumentView,
  callingFromAdminLangauge,
  anchorE2,
  setEditpermission,
  file,
  downloadFiles,
  selectFile,
  disabled,
  cardView,
  showMembers,
  disableMembers,
  handleShowMembers,
  showSelect,
  selectClicked,
  callingFromView,
  callingFromRelationship,
  layouttype,
  disabledCreate,
  showDuplicate,
  showAction,
  disableAction,
  actionList,
  actionItemClick,
  actionLoader,
  anchorElForAction,
  setAnchorElForAction,
  fetchingActions,
  showTreeView,
}) => {
  const { classes } = useStyles();

  const inlineEditOpen = () => {
    if (enableInlineEdit === true) {
      var grid;
      if (callingFromGantt === true) {
        grid = document.getElementById("ContextMenu").ej2_instances[0];
      } else if (callingFromDocumentView === true) {
        grid = document.getElementById("documentViewGrid").ej2_instances[0];
      } else if (callingFromAdminLangauge === true) {
        grid = document.getElementById("LanguageGrid").ej2_instances[0];
      } else {
        grid = document.getElementById("viewGrid").ej2_instances[0];
      }
      if (setEditpermission) {
        setEditpermission(false);
      }

      if (grid.isEdit) {
        grid.closeEdit();
        setBtnClicked(false);
      }
      //setBtnClicked(true)
      setMenuOpen(false);
      //inlineEditCancel()
    } else {
      if (setEditpermission) {
        setEditpermission(true);
      }
      setMenuOpen(false);
      setBtnClicked(false);
    }
    setEnableInlineEdit(!enableInlineEdit);
  };

  useEffect(() => {
    if (setEditpermission) {
      if (enableInlineEdit === true) {
        setEditpermission(true);
      } else {
        setEditpermission(false);
      }
    }
  }, [enableInlineEdit]);

  return (
    <Popover
      open={
        callingFromDocumentView === true ? Boolean(anchorE2) : Boolean(anchorEl)
      }
      anchorEl={callingFromDocumentView === true ? anchorE2 : anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={(e) => listtypeopenClose(e)}
    >
      <ClickAwayListener onClickAway={(e) => listtypeopenClose(e)}>
        <MenuList>
          {showTreeView !== undefined &&
          showTreeView !== null &&
          showTreeView ? null : ((callingFromView !== undefined &&
              callingFromView !== null &&
              callingFromView) ||
              (callingFromRelationship !== undefined &&
                callingFromRelationship !== null &&
                callingFromRelationship &&
                layouttype !== undefined &&
                layouttype !== null &&
                layouttype &&
                layouttype === "View")) &&
            showSelect !== undefined &&
            showSelect !== null ? (
            <MenuItem onClick={selectClicked}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Select"
                size="small"
              >
                <CheckBoxIcon />
              </IconButton>
              {showSelect ? "Select" : "Hide Select"}
            </MenuItem>
          ) : null}

          {showInlineEdit ? (
            <MenuItem onClick={inlineEditOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Inline Edit"
                size="small"
              >
                <DriveFileRenameOutlineIcon />
              </IconButton>
              {enableInlineEdit ? "Disable Inline Edit" : "Enable Inline Edit"}
            </MenuItem>
          ) : null}

          {showDependancy ? (
            <MenuItem onClick={editDependancy} disabled={editDisable}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Edit Dependancy"
                size="small"
              >
                <EditAttributesIcon />
              </IconButton>
              {"Edit Dependancy"}
            </MenuItem>
          ) : null}

          {showAction !== undefined && showAction ? (
            <MenuItem disabled={disableAction} onClick={fetchingActions}>
              <IconButton
                className={classes.marginSpace}
                aria-label="action"
                size="small"
              >
                <CallToActionIcon />
              </IconButton>
              {<LanguageTranslation key="ACTIONS" text="Action" />}
            </MenuItem>
          ) : null}

          <Popover
            open={Boolean(anchorElForAction)}
            anchorEl={anchorElForAction}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={(e) => listtypeopenClose(e)}
          >
            <ClickAwayListener onClickAway={(e) => listtypeopenClose(e)}>
              <MenuList>
                {actionLoader ? (
                  <>
                    <div className="actionSkeletonDiv">
                      <Skeleton variant="text" className="actionSkeleton" />
                      <Skeleton variant="text" className="actionSkeleton" />
                      <Skeleton variant="text" className="actionSkeleton" />
                      <Skeleton variant="text" className="actionSkeleton" />
                    </div>
                  </>
                ) : actionList !== undefined && actionList.length !== 0 ? (
                  actionList?.map((action) => (
                    <MenuItem
                      key={parseInt(action.actionId)}
                      onClick={() => actionItemClick(action)}
                      className="action-items"
                    >
                      {action?.actionName}
                    </MenuItem>
                  ))
                ) : (
                  <div className="no-action-msg">
                    No Action present at the moment
                  </div>
                )}
              </MenuList>
            </ClickAwayListener>
          </Popover>

          {showImport ? (
            <MenuItem onClick={importOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="import"
                size="small"
              >
                <ImportExportIcon />
              </IconButton>
              {<LanguageTranslation key="IMPORT" text="Import" />}
            </MenuItem>
          ) : null}
          {showDocumentView ? (
            <MenuItem
              disabled={disableDocumentView}
              onClick={toolbarDocumentViewOpen}
            >
              <IconButton
                className={classes.marginSpace}
                aria-label="Document view"
                size="small"
              >
                <MenuBookIcon />
              </IconButton>
              {"Document View"}
            </MenuItem>
          ) : null}

          {toolbarViewShow ? (
            <MenuItem disabled={disableView} onClick={toolbarViewOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="view"
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
              {<LanguageTranslation key="VIEW" text="View" />}
            </MenuItem>
          ) : null}

          {disabledCreate ? null : showDuplicate !== undefined &&
            showDuplicate !== null &&
            showDuplicate ? (
            <MenuItem disabled={disableView} onClick={toolbarDuplicateOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Duplicate"
                size="small"
              >
                <ContentCopyIcon />
              </IconButton>
              {<LanguageTranslation key="DUPLICATE" text="Duplicate" />}
            </MenuItem>
          ) : null}

          {showMembers ? (
            <MenuItem
              disabled={disableMembers}
              onClick={(e) => handleShowMembers(e)}
            >
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.marginSpace}
              >
                <GroupsIcon />
              </IconButton>
              {<LanguageTranslation key="SHOW_MEMBERS" text="Show Members" />}
            </MenuItem>
          ) : null}

          {showDelete ? (
            <MenuItem disabled={disableDelete} onClick={(e) => deleteData(e)}>
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.marginSpace}
              >
                <DeleteIcon />
              </IconButton>
              {<LanguageTranslation key="DELETE" text="Delete" />}
            </MenuItem>
          ) : null}

          {columnChooserShow && !cardView ? (
            <MenuItem onClick={columnChooserclick}>
              <IconButton
                aria-label="View column"
                size="small"
                className={classes.marginSpace}
              >
                <ViewWeekIcon />
              </IconButton>
              {"Column Chooser"}
            </MenuItem>
          ) : null}

          {excelExportShow && !cardView ? (
            <MenuItem onClick={excelExport}>
              <IconButton
                aria-label="excel export"
                size="small"
                className={classes.marginSpace}
              >
                <FileDownloadIcon />
              </IconButton>
              {"Excel Export"}
            </MenuItem>
          ) : null}
        </MenuList>
      </ClickAwayListener>
    </Popover>
  );
};

export default memo(UserToolBarMoreOption);
