import ApiUrl from "../apis/ApiUrl";
import relationshipsApi from "../apis/relationshipsApi";
import { configHeader } from "./header-configure";
import { configHeaderwithId } from "./header-configure";
import { configHeaderwithData } from "./header-configure";
import { redirect } from "./redirect";

export const saveRelation = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post("", data, configHeader());
    if (!response.data) {
      response.data = {};
    }
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    }
  }
};

export const fetchRelationValues =
  (relationId, objectId) => async (dispatch) => {
    try {
      const response = await relationshipsApi.get(
        `/values?relationId=${relationId}&objectId=${objectId}`,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      dispatch({ type: "RELATION_DATA", response: response.data });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchRelationValuesContainer = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      `/values`,
      data,
      configHeader()
    );
    dispatch({ type: "RELATION_DATA", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchRelationValuesData =
  (relationId, containerInstanceId, containerId) => async (dispatch) => {
    try {
      const response = await relationshipsApi.get(
        `/values/data?relationId=${relationId}&containerId=${containerId}&containerobjectid=${containerInstanceId}`,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      dispatch({ type: "RELATION_DATA", response: response.data });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const saveRelationData =
  (data, id, typeId, containerInstanceId, containerId) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        "/value",
        data,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      dispatch({ type: "SAVE_RELATION_DATA", response: response.data });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const saveRelationDataDuplicate = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      "/value",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const saveRelationDataOnly = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      "/value",
      data,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "SAVE_RELATION_DATA", response: response.data });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const deleteRelationships = (data, typeId) => async (dispatch) => {
  try {
    const response = await ApiUrl.delete(
      `/relationship?typeId=${typeId}`,
      configHeaderwithId(data)
    );
    dispatch({ type: "DELETE_RELATIONSHIPS", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const deleteRelationshipData = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.delete(
      `/data`,
      configHeaderwithId(data)
    );
    dispatch({ type: "DELETE_RELATIONSHIPS_DATA", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const deleteRelationshipDataOnly = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.delete(
      `/data`,
      configHeaderwithId(data)
    );
    dispatch({ type: "DELETE_RELATIONSHIPS_DATA", response: response });
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const modifyRelation = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.put("", data, configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "SAVE_RELATION", response: response.data });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchRelationsByTypeId = (typeId) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(
      `/relationship?typeId=${typeId}`,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "RELATION_BY_TYPEID", response: response.data });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchRelationsWithConnectedTypeNameByTypeId =
  (typeId) => async (dispatch) => {
    try {
      const response = await relationshipsApi.get(
        `/data/grid?typeId=${typeId}`,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else if (e?.response?.status === 409) {
        return e.response;
      } else {
        return e.response;
      }
      return e.response;
    }
  };

export const updateRelationData = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      "/data/save",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//For Document view Fetch
export const fetchRelationByOrderDocumentview =
  (id, typeId, containerInstanceId, containerId, relationshipId) =>
  async (dispatch) => {
    let instance = {
      containerObjectId: containerInstanceId,
      containerId: containerId,
      objectId: id,
      typeId: typeId,
      relationshipId: relationshipId,
    };

    try {
      const response = await relationshipsApi.post(
        `/data/getorder`,
        instance,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      dispatch({
        type: "FETCH_RELATION_DOCUMENTVIEW",
        response: response.data,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//For Document view Save
export const saveRelationByOrderDocumentview =
  (instance) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        `/data/saveorder`,
        instance,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//For Backlog Relationship data
export const fetchBacklogRelationData = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      `/planning/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To get Relationship data by ID
export const fetchRelationDataByID = (id) => async (dispatch) => {
  try {
    const response = await relationshipsApi.get(`/id?id=${id}`, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    }
    return e.response;
  }
};

export const fetchTypeObjectsRelationValuesByObjectIdandRelationshipId =
  (data) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        `/object/data`,
        data,
        configHeader()
      );
      dispatch({
        type: "FETCH_TYPEOBJECT_RELATION_VALUE_BY_OBJECTID_AND_RELATIONSIPID",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//To attach relationship data
export const attachRelationData = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      "/data/attach",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To detach relationship data
export const detachRelationData = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.delete(
      "/data/detach",
      configHeaderwithData(data)
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To fetch testcase type on the basis of session type
export const fetchTestCaseTypeBySessionTypeId = (id) => async (dispatch) => {
  try {
    const response = await relationshipsApi.get(
      `/relatedtype/manytomany/testcase?testsessiontypeid=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To fetch result type on the basis of session type and test case type
export const fetchResultTypeBySessionTypeIdAndTestCaseTypeId =
  (data) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        `/relatedtype/onetomany/testresult`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//To fetch Connection type on the basis of session type and test case type
export const fetchConnectionTypeBySessionTypeIdAndTestCaseTypeId =
  (data) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        `/relatedtype/connectiontype`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

// API which will provide available relationship for hierarchy table on dashboard
export const fetchRelationshipsForHierarchyTable =
  (data) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        `/heirarchytable`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

// API which will provide relationship attached data according to version id
export const relationshipListAccordingToVersion =
  (data) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        "version/data/object",
        data,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else if (e?.response?.status === 409) {
        return e.response;
      }
    }
  };

// API which will provide relationship data with version id -> adminside
export const fetchRelationshipSettingsWithVersionId =
  (data) => async (dispatch) => {
    try {
      const response = await relationshipsApi.post(
        `/version/get`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchRelationshipVersionsWithIdandAppId =
  (id, appId) => async (dispatch) => {
    try {
      const response = await relationshipsApi.get(
        `/version?id=${id}&appid=${appId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

// API which will clear the suspect for the selected objects
export const clearSuspectForTypeObject = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      `/suspect/clear`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To get suspect data on relationship table click
export const fetchSuspectDataOnRelationClick = (data) => async (dispatch) => {
  try {
    const response = await relationshipsApi.post(
      `/suspect/object/version`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To fetch realtionship listing for suspect
export const fetchRelatioshipListingOnSuspectClick =
  (typeId, objectId) => async (dispatch) => {
    try {
      const response = await relationshipsApi.get(
        `/suspected?typeid=${typeId}&objectid=${objectId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

