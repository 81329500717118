// Note - totally customisable dialog-----------------
import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "tss-react/mui";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ({
  title,
  header,
  handleOK,
  open,
  cancel,
  okText,
  showOkButton,
  cancelText,
  alertMessage,
  dialogWidth,
}) {
  const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={cancel}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  }, styles);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={dialogWidth !== undefined && dialogWidth ? dialogWidth : "sm"}
      open={open}
      aria-labelledby="alert-dialog-slide-title"
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {title ? title : ""}
      </DialogTitle>

      {alertMessage !== undefined && alertMessage ? (
        <Alert severity="error">{alertMessage}</Alert>
      ) : (
        ""
      )}

      <DialogContent>{header}</DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary" size="small">
          {cancelText}
        </Button>
        {showOkButton ? (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleOK}
            content="Submit"
          >
            {okText}
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}
