import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Draggable from "react-draggable";
/*------------------------Material imports starts-----------------------------------*/
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Paper,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
/*------------------------Material imports ends--------------------------------------*/
/*---------------------------------Common files starts-------------------------------*/
import "../../../Css/Common.css";
import {
  DialogTitle,
  DialogTitleMobile,
} from "../FuncNComponents/CommonComponents";
import { exists } from "../FuncNComponents/CommonFunctions";
import LanguageTranslation from "../../../../Language/LanguageTranslation";
import AttachmentToolbar from "../Toolbars/AttachmentToolbar";
import GridLoader from "../../../../../Loaders/GridLoader";
import FormatGridDataForLayouts from "../../../../formatGridNData/FormatGridDataForLayouts";
import { DynamicGrid } from "../../../../formatGridNData/DynamicGrid";
import SnackBar from "../../../../SnackBar";
import ConfirmationBasicDialog from "../../../../common/ConfirmationBasicDialog";
/*---------------------------------Common files starts-----------------------------------*/
/*---------------------------------Common files starts-----------------------------------*/
import { typewithRelationId } from "../../../../actions/typeRefactoredActions";
import { fetchRelationValuesContainer } from "../../../../actions/relationshipsActions";
import { fetchRelationValuesExternal } from "../../../../actions/integrationApiActions";
/*---------------------------------Common files starts-----------------------------------*/

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .MuiDialog-paperWidthMd": {
      overflow: "hidden",
    },
    "& .MuiDialog-paper::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export default function TypeObjectDialogExternal(props) {
  const { classes, cx } = useStyles();

  const dispatch = useDispatch();
  const myGrid = useRef([]);
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [msg, setMsg] = useState("");
  const [relnDatIds, setRelnDatIds] = useState();
  const [objectId, setObjectId] = useState(null);
  const [bodyData, setbodyData] = useState([]);
  const [apiRespondedData, setApiRespondedData] = useState();
  const [backDrop, setBackDrop] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [relnRef, setRelnRef] = useState({ relnId: "", type: "" });
  const [dialogSize, setDialogSize] = useState("lg");
  const [viewData, setViewData] = useState({});
  const [openViewPop, setOpenViewPop] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [filteredattributes, setFilteredAttributes] = useState([]);
  const [clearRowSelection, setClearRowSelection] = useState(false);
  const [changedAttribute, setChangedAttribute] = useState([]);
  const [unsavedChangesDialog, setUnsavedChangeDialog] = useState(false);
  /*---------------------------------Initial loads-----------------------------------*/
  useEffect(() => {
    setbodyData([]);
    setBackDrop(true);
    setLoader(true);
  }, []);
  /*---------------------------------Initial loads-----------------------------------*/
  /*---------------------------------Title backDrop-----------------------------------*/
  useEffect(() => {
    if (props.typeName && props.typeName !== "") {
      setBackDrop(false);
    }
  }, [props.typeName]);
  /*---------------------------------Title backDrop-----------------------------------*/
  /*---------------------------------Preview close when multiple select-----------------------------------*/
  useEffect(() => {
    if (selectedData && selectedData.length && selectedData.length > 1) {
      setOpenViewPop(false);
    }
  }, [selectedData]);
  /*---------------------------------Preview close when multiple select-----------------------------------*/
  /*---------------------------------Whenever ids already selected in main grid ----------------------------*/
  useEffect(() => {
    let cyclicData = [];
    /*-------------------Relationship type cyclic or not----------------------*/
    if (
      props.typeId &&
      props.parentObjectId &&
      props.initialRelationData &&
      props.relationShipId &&
      props.initialRelationData[props.relationShipId] &&
      props.initialRelationData[props.relationShipId].id &&
      props.initialRelationData[props.relationShipId].id === props.typeId
    ) {
      cyclicData.push(props.parentObjectId);
    }
    /*-------------------Relationship type cyclic or not----------------------*/
    if (props.relationData && props.relationData.length) {
      let finalData = props.relationData.map((obj) => obj.id);
      setRelnDatIds(finalData.concat(cyclicData));
    } else if (props.relationData && props.relationData.length === 0) {
      setRelnDatIds(cyclicData);
    }
  }, [props.relationData]);
  /*---------------------------------Whenever ids already selected in main grid --------------------------*/
  /*--------------------------------Data customize to show in grid ----------------------------------*/
  // useEffect(() => {
  //   if (
  //     props.containerId &&
  //     (props.containerInstanceId || props.containerInstanceId === 0) &&
  //     props.widget &&
  //     props.relationShipId &&
  //     props.relationShipId !== "" &&
  //     props.component === undefined
  //   ) {
  //     let parsedWidget;
  //     if (props.widget.properties !== undefined && props.widget.properties) {
  //       parsedWidget = JSON.parse(props.widget.properties);
  //     }
  //     let input = {
  //       relationId: parseInt(props.relationShipId),
  //       objectId: null,
  //       containerId: parseInt(props.containerId),
  //       containerInstanceId:
  //         props.containerInstanceId === 0
  //           ? props.containerInstanceId
  //           : parseInt(props.containerInstanceId),
  //       filterSetting:
  //         parsedWidget && parsedWidget != null && parsedWidget.relationFilters
  //           ? parsedWidget.relationFilters
  //           : "",
  //       defaultColumn:
  //         parsedWidget &&
  //         parsedWidget != null &&
  //         parsedWidget.relationDefaultColumn
  //           ? parsedWidget.relationDefaultColumn
  //           : [],
  //     };
  //     dispatch(fetchRelationValuesContainer(input))
  //       .then((obj) => {
  //         if (obj && obj.data && obj.data.dataObjects) {
  //           /*-------------------Add dataset which are pre attached----------------------*/
  //           let totalGetIds = obj.data.dataObjects.map((resdat) => resdat.id);
  //           let filteredData = [];
  //           if (
  //             props.initialRelationData &&
  //             props.relationShipId &&
  //             props.initialRelationData[props.relationShipId] &&
  //             props.initialRelationData[props.relationShipId].dataObjects &&
  //             props.initialRelationData[props.relationShipId].dataObjects
  //               .length &&
  //             props.initialRelationData[props.relationShipId].dataObjects
  //               .length > 0
  //           )
  //             filteredData = props.initialRelationData[
  //               props.relationShipId
  //             ].dataObjects.filter((dat) => !totalGetIds.includes(dat.id));
  //           /*-------------------Add dataset which are pre attached----------------------*/
  //           console.log(filteredData);
  //           setApiRespondedData(obj.data.dataObjects.concat(filteredData));
  //         }
  //       })
  //       .catch((err) => {
  //         setApiRespondedData([]);
  //       });
  //   }
  // }, [
  //   dispatch,
  //   props.containerId,
  //   props.containerInstanceId,
  //   props.relationShipId,
  //   props.widget,
  // ]);

  useEffect(() => {
    if (
      props.containerId &&
      (props.containerInstanceId || props.containerInstanceId === 0) &&
      props.relationShipId &&
      props.relationShipId !== ""
    ) {
      let input = {
        relationId: parseInt(props.relationShipId),
        objectId: props.parentObjectId.toString(),
        containerId: parseInt(props.containerId),
        containerInstanceId:
          props.containerInstanceId === 0
            ? props.containerInstanceId
            : parseInt(props.containerInstanceId),
      };
      dispatch(fetchRelationValuesExternal(input))
        .then((obj) => {
          if (obj && obj.data) {
            // /*-------------------Add dataset which are pre attached----------------------*/
            // let totalGetIds = obj.data.map((resdat) => resdat.id);
            // let filteredData = [];

            // if (
            //   props.relationData &&
            //   props.relationShipId &&
            //   props.relationData.length > 0
            // )
            //   filteredData = props.relationData.filter(
            //     (dat) => !totalGetIds.includes(dat.id)
            //   );
            // /*-------------------Add dataset which are pre attached----------------------*/
            // setApiRespondedData(obj.data.concat(filteredData));
            // console.log(relnDatIds);
            setApiRespondedData(obj.data);
          }
        })
        .catch((err) => {
          setApiRespondedData([]);
        });
    }
  }, [
    dispatch,
    props.containerId,
    props.containerInstanceId,
    props.relationShipId,
  ]);

  useEffect(() => {
    if (relnDatIds && apiRespondedData && attributes && props.allUsers) {
      /*-------------------Remove from dataset which are pre attached----------------------*/
      let filteredData = apiRespondedData.filter(
        (dat) => relnDatIds !== undefined && !relnDatIds.includes(dat.id)
      );
      /*-------------------Remove from dataset which are pre attached----------------------*/
      const promise1 = new Promise((resolve) => {
        let foundData = FormatGridDataForLayouts(
          filteredData,
          attributes,
          props.allUsers
        );

        resolve(foundData);
      });
      promise1.then((dataFetched) => {
        setbodyData(dataFetched);
        setLoader(false);
      });
    }
  }, [apiRespondedData, relnDatIds, attributes, props.allUsers]);
  /*--------------------------------Data customize to show in grid ----------------------------------*/
  /*------------------Set attribute and typeId by relationship---------------------------*/
  useEffect(() => {
    if (
      props.relationShipId != "" &&
      (props.containerInstanceId || props.containerInstanceId === 0) &&
      props.containerId &&
      props.containerId != null &&
      props.containerId != ""
    ) {
      let properties;
      if (
        props?.widget !== undefined &&
        props?.widget &&
        props?.widget?.properties !== undefined &&
        props?.widget?.properties
      ) {
        properties = JSON.parse(props?.widget?.properties);
      }

      let defaultColumn =
        properties !== undefined &&
        properties &&
        properties.relationDefaultColumn
          ? JSON.parse(properties.relationDefaultColumn)
          : [];
      let formattedData = {
        containerObjectId: parseInt(props.containerInstanceId),
        containerId: parseInt(props.containerId),
        relationId: props.relationShipId ? parseInt(props.relationShipId) : "",
        typeAttributes: defaultColumn,
      };
      dispatch(typewithRelationId(formattedData)).then((datType) => {
        if (datType.data && datType.data.id) {
          if (datType.data.attributes) {
            setAttributes(datType.data.attributes);
          }
        }
      });
    }
  }, [props.relationShipId, props.containerInstanceId, props.containerId]);
  /*------------------Set attribute and typeId by relationship---------------------------*/
  /*------------------Code to order attribute in grid---------------------------*/
  useEffect(() => {
    if (attributes && attributes.length > 0) {
      let attrData = [];
      if (
        props.widget !== undefined &&
        props.widget &&
        props.widget.properties !== undefined &&
        props.widget.properties
      ) {
        let widgetProperties = JSON.parse(props.widget.properties);
        if (
          widgetProperties.relationDefaultColumn &&
          widgetProperties.relationDefaultColumn !== ""
        ) {
          let col = JSON.parse(widgetProperties.relationDefaultColumn);
          if (attributes) {
            let filAttr = [];
            col.forEach((relV) => {
              let data = exists(attributes, relV);
              if (data && data.length && data.length > 0) {
                filAttr.push(data[0]);
              }
            });
            attrData = filAttr;
          }
        } else {
          let removedAttr = attributes.filter(
            (obj) =>
              obj.internalName === "CREATED_ON" ||
              obj.internalName === "MODIFIED_ON" ||
              obj.internalName === "CREATED_BY" ||
              obj.internalName === "MODIFIED_BY"
          );

          let filAttr = attributes.filter(
            (obj) =>
              obj.internalName !== "CREATED_ON" &&
              obj.internalName !== "MODIFIED_ON" &&
              obj.internalName !== "CREATED_BY" &&
              obj.internalName !== "MODIFIED_BY" &&
              obj.dataType.key !== "WORKLOG" &&
              obj.dataType.key !== "COMMENTS"
          );

          attrData = filAttr.concat(removedAttr);
        }
      } else {
        let removedAttr = attributes.filter(
          (obj) =>
            obj.internalName === "CREATED_ON" ||
            obj.internalName === "MODIFIED_ON" ||
            obj.internalName === "CREATED_BY" ||
            obj.internalName === "MODIFIED_BY"
        );

        let filAttr = attributes.filter(
          (obj) =>
            obj.internalName !== "CREATED_ON" &&
            obj.internalName !== "MODIFIED_ON" &&
            obj.internalName !== "CREATED_BY" &&
            obj.internalName !== "MODIFIED_BY" &&
            obj.dataType.key !== "WORKLOG" &&
            obj.dataType.key !== "COMMENTS"
        );

        attrData = filAttr.concat(removedAttr);
      }
      setFilteredAttributes(attrData);
    }
  }, [attributes, props.widget]);
  /*------------------Code to order attribute in grid---------------------------*/
  /*------------------Popup size change function---------------------------*/
  const handleSize = () => {
    if (dialogSize === "md") {
      setDialogSize("lg");
    } else if (dialogSize === "lg") {
      setDialogSize("md");
    }
  };
  /*------------------Popup size change function---------------------------*/
  /*------------------Selected checkbox in grid function---------------------------*/
  const toggleData = (e) => {
    if (e.name === "rowSelected") {
      if (e.data && e.data.id) {
        const finalData = [...selectedData, e.data];
        const removingDuplicates = finalData?.filter(
          (ele, ind) =>
            ind === finalData?.findIndex((elem) => elem?.id === ele?.id)
        );
        // setSelectedData([...selectedData, e.data]);
        setSelectedData(removingDuplicates);
      } else if (e.rowIndexes && e.rowIndexes.length > 0) {
        const finalData = selectedData.concat(e.data);
        const removingDuplicates = finalData?.filter(
          (ele, ind) =>
            ind === finalData?.findIndex((elem) => elem?.id === ele?.id)
        );
        // setSelectedData(selectedData.concat(e.data));
        setSelectedData(removingDuplicates);
      }
    } else {
      if (e.data && e.data.id) {
        const finalData = selectedData.filter((obj) => obj.id !== e.data.id);
        let removingDuplicates = finalData?.filter(
          (ele, ind) =>
            ind === finalData?.findIndex((elem) => elem?.id === ele?.id)
        );
        // setSelectedData(selectedData.filter((obj) => obj.id !== e.data.id));
        setSelectedData(removingDuplicates);
      } else if (e.data && e.data.length > 0) {
        let dataIds = e.data?.map(
          (obj) => obj !== undefined && obj && obj.id !== undefined && obj.id
        );
        let selData = selectedData?.filter((obj) => !dataIds.includes(obj.id));
        let uniqueItems = selData?.filter(
          (ele, ind) =>
            ind === selData?.findIndex((elem) => elem?.id === ele?.id)
        );
        setSelectedData(uniqueItems);
      }
    }
  };
  /*------------------Selected checkbox in grid function---------------------------*/
  /*------------------Any row selected function---------------------------*/
  const recordClick = (args) => {
    if (
      (args.name &&
        args.name === "recordClick" &&
        args.target.className === "e-rowcell e-selectionbackground e-active") ||
      args.target.className ===
        "e-rowcell e-templatecell e-focus e-selectionbackground e-active" ||
      args.target.className === "e-rowcell e-templatecell" ||
      args.target.className === "e-rowcell" ||
      args.target.className === "e-rowcell e-lastrowcell" ||
      args.target.className === "e-rowcell e-templatecell e-lastrowcell" ||
      args.target.className ===
        "e-rowcell e-lastrowcell e-selectionbackground e-active" ||
      args.target.className === "e-rowcell e-selectionbackground e-active" ||
      args.target.className ===
        "e-rowcell e-lastrowcell e-focus e-selectionbackground e-active" ||
      args.target.className ===
        "e-rowcell e-focus e-selectionbackground e-active"
    ) {
      setClearRowSelection(false);
      if (args.rowData && args.rowData.id) {
        setClearRowSelection(true);
        setViewData(args.rowData);
        setObjectId(args.rowData.id);
        setOpenViewPop(true);
      }
    }
  };
  /*------------------Any row selected function---------------------------*/
  /*--------------------For choosing column , excel export, search in the toolbar----------------------*/
  const columnChooserclick = () => {
    setRelnRef({
      ...relnRef,
      relnId: props.relationShipId,
      type: "columnChooser",
    });
  };

  const excelExport = () => {
    setRelnRef({
      ...relnRef,
      relnId: props.relationShipId,
      type: "excelExport",
    });
  };

  const searchChange = (value) => {
    setRelnRef({
      ...relnRef,
      relnId: props.relationShipId,
      type: "search",
      val: value,
    });
  };
  /*--------------------For choosing column , excel export, search in the toolbar----------------------*/
  /*------------------------closing snackbar----------------*/
  const closeAlert = () => {
    setAlert(false);
  };
  /*----------------------------------------------------------------------*/
  /*--------------------Close poupup by saving items selected----------------------*/
  const save = () => {
    if (
      props.relationshipunique_multivalued === false &&
      (props.relationData.length >= 1 || selectedData.length > 1)
    ) {
      setMsg("Sorry! You cannot add more than one data...");
      setSeverity("error");
      setAlert(true);
    } else {
      if (
        selectedData !== undefined &&
        selectedData &&
        selectedData.length !== 0
      ) {
        let arr = selectedData;
        // filtering out items with duplicate Ids
        let uniqueItems = arr?.filter(
          (ele, ind) => ind === arr?.findIndex((elem) => elem?.id === ele?.id)
        );
        props.attachData(uniqueItems);
      } else {
        props.attachData(selectedData);
      }
    }
  };
  /*--------------------Close poupup by saving items selected----------------------*/

  const closeDialog = () => {
    if (
      changedAttribute !== undefined &&
      changedAttribute &&
      changedAttribute.length > 0
    ) {
      setUnsavedChangeDialog(true);
    } else {
      props.setShowModal();
      setUnsavedChangeDialog(false);
    }
  };

  const handleCommentSaveWarningDialog = () => {
    setChangedAttribute([]);
    setUnsavedChangeDialog(false);
    props.setShowModal();
  };

  return (
    <Dialog
      className={cx(classes.root, props.className)}
      fullWidth={true}
      maxWidth={dialogSize === "md" ? "md" : "lg"}
      fullScreen={dialogSize === "md" ? false : true}
      open={props.open}
      PaperComponent={PaperComponent}
      scroll="paper"
      aria-labelledby="draggable-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <SnackBar
        alert={alert}
        closeAlert={closeAlert}
        severity={severity}
        msg={msg}
      />
      {/*-----------------Dialogue Title--------------------*/}
      {props.width <= 768 ? (
        <DialogTitleMobile loading={backDrop} save={save} onClose={closeDialog}>
          {backDrop ? (
            <div style={{ width: 100 }}>
              {" "}
              <Skeleton />
            </div>
          ) : props.typeName ? (
            "Attach " + props.typeName
          ) : (
            ""
          )}
        </DialogTitleMobile>
      ) : (
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={closeDialog}
          dialogSize={dialogSize}
          handleSize={handleSize}
        >
          {backDrop ? (
            <div style={{ width: 100 }}>
              {" "}
              <Skeleton />
            </div>
          ) : props.typeName ? (
            "Attach " + props.typeName
          ) : (
            ""
          )}
        </DialogTitle>
      )}
      {/*-----------------Dialogue Title--------------------*/}
      <div
        style={{
          width: "100%",
          paddingRight: 0,
        }}
      >
        {" "}
        {/*-----------------Dialogue content--------------------*/}
        <DialogContent className="dialogueContent">
          <div className="control-pane">
            <div className="control-section row">
              <AttachmentToolbar
                columnChooserclick={columnChooserclick}
                searchChange={searchChange}
                excelExport={excelExport}
                relnRef={relnRef}
              />
              {loader ? (
                <GridLoader />
              ) : (
                <DynamicGrid
                  toggleData={toggleData}
                  bodyData={bodyData}
                  attributes={filteredattributes}
                  stateField={props.stateField}
                  relationshipId={props.relationShipId}
                  relnRef={relnRef}
                  recordClick={recordClick}
                  dateFormat={props.dateFormat}
                  dateTimeFormat={props.dateTimeFormat}
                  clearRowSelection={clearRowSelection}
                  myGrid={myGrid}
                />
              )}
            </div>
          </div>
        </DialogContent>
        {/*-----------------Dialogue content--------------------*/}
        {/*-----------------Footer buttons--------------------*/}
        <DialogActions className="dialogueAction">
          <Button
            negative
            onClick={() => {
              props.setShowModal(false);
              setOpenViewPop(false);
              if (props.relationValue && props.relationValue.dataObjects) {
                props.relationValue.dataObjects.forEach(
                  (object) => (object.checked = false)
                );
              }
            }}
            color="primary"
            size="small"
          >
            <LanguageTranslation key="CANCEL" text="Cancel" />
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            type="submit"
            content="Submit"
            onClick={save}
            startIcon={<CheckCircleIcon />}
          >
            <LanguageTranslation key="SAVE" text="Save" />
          </Button>
        </DialogActions>
        {/*-----------------Footer buttons--------------------*/}
      </div>

      {unsavedChangesDialog ? (
        <ConfirmationBasicDialog
          open={unsavedChangesDialog}
          header={
            <LanguageTranslation
              key="CONFIRMATION_UNSAVED_COMMENTS_LAYOUT_CANCEL"
              text="There are some unsaved comments in your layout ,do you still want to close it ?"
            />
          }
          cancel={() => setUnsavedChangeDialog(false)}
          title={<LanguageTranslation key="WARNING" text="Warning" />}
          handleOK={handleCommentSaveWarningDialog}
          okText={<LanguageTranslation key="YES" text="Yes" />}
          showOkButton={true}
          cancelText={<LanguageTranslation key="CANCEL" text="Cancel" />}
        ></ConfirmationBasicDialog>
      ) : (
        ""
      )}
    </Dialog>
  );
}
